import { useRouter } from 'next/router'
import { CSSProperties } from 'react'

import { CreatorEvent, TrackingPageSource } from '~/analytics/CreatorEvent'
import { Variant } from '~/components/shared/text/Typography'
import { useAnalytics } from '~/context'
import { ProfileBasicSummaryType } from '~/endpoints/model'

import { BoardAuthorWithoutContext } from './BoardAuthorWithoutContext'

interface BoardAuthorProps {
  author: ProfileBasicSummaryType
  desktopPhotoSize?: number
  mobilePhotoSize?: number
  photoGap?: number
  titleStyle?: CSSProperties
  containerStyle?: CSSProperties
  titleVariant?: Variant
  tagVariant?: Variant
  showAvailableForHireTag?: boolean
  hideLabelPhoto?: boolean
  trackingSource: TrackingPageSource
}

export const BoardAuthor: React.FC<BoardAuthorProps> = ({
  author,
  desktopPhotoSize = 40,
  mobilePhotoSize = 40,
  photoGap = 12,
  titleStyle = {},
  containerStyle = {},
  titleVariant = 'body2SerifItalic',
  tagVariant,
  showAvailableForHireTag = false,
  hideLabelPhoto,
  trackingSource,
}) => {
  const router = useRouter()
  const thatchSegment = useAnalytics()

  const { hireable, username, uid } = author

  const handleClick = async (e: any) => {
    if (hireable) {
      thatchSegment.trackCreatorEvent(CreatorEvent.HireMeClicked, {
        source: trackingSource,
        creatorToken: uid,
        creatorUsername: username,
      })
    } else if (showAvailableForHireTag) {
      await router.push(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            scroll: window.scrollY,
            tab: 'experts',
            seller: author.username,
          },
        },
        undefined,
        { shallow: true }
      )
    }
  }

  return (
    <BoardAuthorWithoutContext
      author={author}
      desktopPhotoSize={desktopPhotoSize}
      mobilePhotoSize={mobilePhotoSize}
      photoGap={photoGap}
      titleStyle={titleStyle}
      containerStyle={containerStyle}
      titleVariant={titleVariant}
      tagVariant={tagVariant}
      showAvailableForHireTag={showAvailableForHireTag}
      hideLabelPhoto={hideLabelPhoto}
      handleGuideClick={handleClick}
      click_source={trackingSource}
    />
  )
}
