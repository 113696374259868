import { useUser } from 'next-firebase-auth'
import { useCallback, useEffect, useState } from 'react'

import { notify } from '~/components/shared/notify'
import { ProfileSummaryType } from '~/endpoints/model'
import { fetchProfileSummary } from '~/endpoints/user'

export const useProfielSummary = ({ username, failSilently }: { username: string, failSilently?: boolean }) => {
  const authUser = useUser()
  const [isLoading, setLoading] = useState(false)
  const [profileSummary, setProfileSummary] = useState<ProfileSummaryType | null>(null)

  const fetchProfileSummaryData = useCallback(async () => {
    try {
      setLoading(true)
      const data = await fetchProfileSummary(`@${username}`, authUser?.getIdToken)
      setProfileSummary(data)
    } catch (err) {
      console.error(err)
      if (!failSilently) {
        notify(true, 'Failed to fetch user information')
      }
    } finally {
      setLoading(false)
    }
  }, [authUser?.getIdToken, username])

  useEffect(() => {
    if (username) {
      fetchProfileSummaryData()
    }
  }, [fetchProfileSummaryData, username])

  return {
    profileSummary,
    isLoading,
  }
}

export const useProfielSummaryAnon = ({ username, failSilently }: { username: string, failSilently?: boolean }) => {
  const [isLoading, setLoading] = useState(false)
  const [profileSummary, setProfileSummary] = useState<ProfileSummaryType | null>(null)

  const fetchProfileSummaryData = useCallback(async () => {
    try {
      setLoading(true)
      const data = await fetchProfileSummary(`@${username}`, undefined)
      setProfileSummary(data)
    } catch (err) {
      console.error(err)
      if (!failSilently) {
        notify(true, 'Failed to fetch user information')
      }
    } finally {
      setLoading(false)
    }
  }, [username])

  useEffect(() => {
    if (username) {
      fetchProfileSummaryData()
    }
  }, [fetchProfileSummaryData, username])

  return {
    profileSummary,
    isLoading,
  }
}
